import React from "react";
import team from "../Assets/team/team.jpg";
import team2 from "../Assets/team/team2.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const OurTeam = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-about">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Our Team</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  Our team is comprised of some of the best people in the
                  business. We're dedicated to providing you with a superior
                  product and excellent service.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12 mt-4">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Nico Mkhari</h5>
                        <p className="card-text ntgr-center-text">
                          CEO - Founder
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team2} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Lerato Lushaba</h5>
                        <p className="card-text ntgr-center-text">
                          Campaign Specialist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Frans Masemula</h5>
                        <p className="card-text ntgr-center-text">Engineer</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team2} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Moses Magagula</h5>
                        <p className="card-text ntgr-center-text">Engineer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12 mt-4">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Nico Mkhari</h5>
                        <p className="card-text ntgr-center-text">
                          CEO - Founder
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team2} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Lerato Lushaba</h5>
                        <p className="card-text ntgr-center-text">
                          Campaign Specialist
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Frans Masemula</h5>
                        <p className="card-text ntgr-center-text">Engineer</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 col-sm-12">
                    <div className="card shadow-sm">
                      <img src={team2} className="rounded-circle" alt="..." />
                      <div className="card-body ngtr-center-team">
                        <h5 className="card-title">Moses Magagula</h5>
                        <p className="card-text ntgr-center-text">Engineer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurTeam;
