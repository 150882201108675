import React from "react";
import aboutBanner from "../Assets/404-banner.jpg";
const Page404 = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h2 className="text-center">Page 404</h2>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p className="text-center">
                  This is the 404 page. Sorry, we can't find what you're looking
                  for. We'll help you get back on track.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
