import React from "react";
import about from "../Assets/about.jpg";
import about2 from "../Assets/about2.png";
// import accreditation from "../Assets/accreditation.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const OurDNA = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-about">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mb-5 mt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                <h3>Our DNA</h3>
                <p>
                  NTGR Engineering is a multifunctional engineering and
                  infrastructure solutions company with track record of 18 years
                  offering turnkey-engineering services and Asset Management
                  Services to a broad and diverse client base across the mining,
                  manufacturing and public sector infrastructure development
                  sectors in the SADC region.{" "}
                </p>
                <p className="mt-4">
                  Our specialist divisions work closely with our project teams,
                  to provide clients with full service solutions in Civil,
                  Structural, Mechanical & Electrical project engineering, from
                  concept to commission. Our aim is to develop industry and
                  client specific solutions that maximize profit whilst
                  minimizing costs for our clients by focusing our efforts on
                  continuous innovation and optimization of our processes and
                  technologies. Our expertise, commitment to quality and
                  infectious enthusiasm has resulted in the delivery of results
                  that consistently exceed our client’s expectations.
                </p>
                <p className="mt-4">
                  As a 100% black-owned company, we are actively promoting
                  government priorities and are fully committed to supporting
                  the government’s socioeconomic transformation and
                  sustainability agenda in the sector we operate in. We aim to
                  create jobs, empower communities and increase economic
                  participation for Black South Africans in particular women,
                  who as at the forefront of our transformation agenda as a
                  business.
                </p>
                <p className="mt-4">
                  NTGR engineering is TOP empowerment company, a proud member of
                  the Mining equipment manufacturers of South Africa (MEMSA) and
                  a winner at manufacturing excellence awards 2022 for the
                  category Manufacturing solutions.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                <div class="embed-responsive embed-responsive-16by9 mt-4">
                <iframe class="embed-responsive-item w-100" height="315" src="https://www.youtube.com/embed/Up_Pp_JToLQ?si=Gdn7aoDzDFhH_cz_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-gray-about">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                <div className="row justify-content-end">
                  <div className="col-lg-10 col-md-10 col-12 col-sm-12">
                    <h3 className="ntgr-about-subheading">Our Vision </h3>
                    <p className="mt-4">
                      To be a market leader and company of choice for all
                      engineering, manufacturing and infrastructure development
                      services and related products in Africa.
                    </p>
                    <h3 className="mt-4">Our Mission</h3>
                    <p className="mt-4">
                      We are devoted to driving efficiencies, offering excellent
                      service delivery and outstanding performance to all our
                      clients and stakeholders. Our on-going obligation to our
                      clients and stakeholders is to provide high-quality
                      services and on time delivery within the set out budgets
                      ultimately ensuring value for money. We aim to ensure
                      client satisfaction and maintain healthy client
                      relationships by putting our best foot forward and
                      bringing to each project a positive and professional
                      attitude, accountability and high ethical standards and
                      values. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 col-sm-12 p-0">
                <img src={about} className="width100" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 col-sm-12 p-0">
                <img src={about2} className="width100" alt="" />
              </div>
              <div className="col-lg-6 col-md-6 col-12 col-sm-12">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-10 col-12 col-sm-12">
                    <h3 className="ntgr-about-subheading">Why Choose Us</h3>
                    <p className="mt-4">
                      We are 135% B-BBEE contributor and take pride in our
                      business ethics. We are agile in our approach and creating
                      value for our clients through utilizing innovative
                      technologies and streamlined processes in order to ensure
                      we work within our client budgets is our top priority. We
                      strike a healthy balance between profitability, resources,
                      environmental stewardship and social responsibility. Job
                      creation, employment equity, empowerment, and a safe
                      working environment are all top of our agenda for us at
                      NTGR Engineering. Our employees are not only highly
                      skilled and experts in their field but are enthusiastic
                      about the service they provide and have a strong sense of
                      teamwork. Through passionate, motivated, professional, and
                      strong teams, we continue to develop and utilize our
                      people’s skills in order to achieve client satisfaction
                      and consistent growth.
                    </p>
                    <p className="mt-4">By choosing us, you are guaranteed: </p>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item ntgr-values-list">
                        <span className="material-symbols-outlined ntgr-why-icons">
                          approval_delegation
                        </span>
                        <p className="ms-4">
                          Commitment to providing quality, value and on time
                          delivery of services
                        </p>
                      </li>
                      <li className="list-group-item ntgr-values-list">
                        <span className="material-symbols-outlined ntgr-why-icons">
                          approval_delegation
                        </span>
                        <p className="ms-4">
                          Commitment to client satisfaction
                        </p>
                      </li>
                      <li className="list-group-item ntgr-values-list">
                        <span className="material-symbols-outlined ntgr-why-icons">
                          approval_delegation
                        </span>
                        <p className="ms-4">
                          Commitment to profit maximization at minimal costs
                        </p>
                      </li>
                      <li className="list-group-item ntgr-values-list">
                        <span className="material-symbols-outlined ntgr-why-icons">
                          approval_delegation
                        </span>
                        <p className="ms-4">
                          Extensive capabilities and expertise
                        </p>
                      </li>
                      <li className="list-group-item ntgr-values-list">
                        <span className="material-symbols-outlined ntgr-why-icons">
                          approval_delegation
                        </span>
                        <p className="ms-4">
                          Commitment to preservation of our environment
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-values-bg">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 col-12 col-sm-12 p-0">
                <h3 className="mt-5 text-center">Our Values</h3>
                <p className="text-center">
                  We strive to create an inclusive environment where each
                  individual has an opportunity to make an impact. We achieve
                  this through a set of common values that bind us together.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10 col-12 col-sm-12">
                <div className="row justify-content-center">
                  <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <span className="material-symbols-outlined ntgr-values-icons">
                          autorenew
                        </span>
                        <p>Integrity</p>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <span className="material-symbols-outlined ntgr-values-icons">
                          verified
                        </span>
                        <p>Excellence in leadership</p>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <span className="material-symbols-outlined ntgr-values-icons">
                          diversity_2
                        </span>
                        <p>Client focused</p>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <span className="material-symbols-outlined ntgr-values-icons">
                          engineering
                        </span>

                        <p>Innovation/Agility</p>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <span className="material-symbols-outlined ntgr-values-icons">
                          done_all
                        </span>
                        <p>Transparency</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurDNA;
