import React from "react";
import assetManagement3 from "../Assets/assetManagement6.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const Sherq = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>SHERQ</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  NTGR Engineering is committed to complying with the SHERQ
                  standards and requirements as set out on the SHERQ Management
                  Policy. We are committed to the provision and promotion of a
                  healthy and a safe work environment for employees and clients,
                  minimizing risks and the preservation of our environment. We
                  demonstrate this by providing basic SHEQ training for
                  employees, adding visible warnings and emergency plans as well
                  as investing in personal protection equipment amongst other
                  things. Our teams are first aid trained and are fit to work in
                  various client environments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-7 col-12 col-sm-12 mt-4">
            <h6 className="text-center">Deliverables</h6>
            <img src={assetManagement3} className="width100" alt="NTGR" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Sherq;
