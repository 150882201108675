import "./App.css";
import Layout from "./Components/Layout";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import OurDNA from "./Pages/OurDNA";
import Header from "./Components/Header";
import OurClients from "./Pages/OurClients";
import OurTeam from "./Pages/OurTeam";
import AssetManagement from "./Pages/AssetManagement";
import ConstructionServices from "./Pages/ConstructionServices";
import EngineeringDesign from "./Pages/EngineeringDesign";
import TurnkeySolutions from "./Pages/TurnkeySolutions";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";
import Sherq from "./Pages/sherq";
import News from "./Pages/News";
import PartnersAndAccreditations from "./Pages/Partners";
import Gallery from "./Pages/Gallery";
import Page404 from "./Pages/404Page";
import NewsPage from "./Pages/NewsPage";
function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Header />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} index />
          <Route path="our-dna" element={<OurDNA />} />
          <Route path="our-clients" element={<OurClients />} />
          <Route path="our-team" element={<OurTeam />} />
          <Route path="asset-management" element={<AssetManagement />} />
          <Route
            path="construction-services"
            element={<ConstructionServices />}
          />
          <Route path="engineering-design" element={<EngineeringDesign />} />
          <Route path="turnkey-solutions" element={<TurnkeySolutions />} />
          <Route path="contact" element={<Contact />} />
          <Route path="projects" element={<Projects />} />
          <Route path="sherq" element={<Sherq />} />
          <Route path="news" element={<News />} />
          <Route path="gallery" element={<Gallery />} />
          <Route
            path="partners-and-accreditations"
            element={<PartnersAndAccreditations />}
          />
          <Route path="*" element={<Page404 />} />
          <Route path="/newspage/:id" element={<NewsPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Layout>
  );
}

export default App;
