import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aboutBanner from "../Assets/about-banner.jpg";
import {
  faBuilding,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [errMessageOpen, setErrMessageOpen] = useState(false);
  const [errMessageSent, setErrMessageSent] = useState(false);
  const HandleContact = (e) => {
    e.preventDefault();
    if (fullName === "" || email === "" || message === "") {
      setErrMessage("Populate all fields");
      setErrMessageOpen(true);
      setErrMessageSent(false);
    } else {
      axios({
        url: "https://ntgr.co.za/endpoints/email.php",
        method: "POST",
        headers: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          email: email,
          message: message,
        },
      }).then((response) => {
        console.log(response.status);
        if (response.status == "200") {
          setErrMessageSent(true);
          setErrMessage("Email sent");
        }
      });
    }
  };
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Contact Us</h3>
            <div className="row">
              <div className="col-lg-7 col-md-6 col-12 col-sm-12">
                {errMessageOpen && (
                  <div className="alert alert-danger" role="alert">
                    {errMessage}
                  </div>
                )}

                {errMessageSent && (
                  <div className="alert alert-success" role="alert">
                    {errMessage}
                  </div>
                )}
                <form
                  action="https://ntgr.co.za/endpoints/email.php"
                  onSubmit={HandleContact}
                >
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Full name"
                      name="fullName"
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="info@ntgr.co.za"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="message"
                      rows="3"
                      name="message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary ntgr-contact-btn"
                  >
                    Get in touch
                  </button>
                </form>
              </div>
              <div className="col-lg-5 col-md-5 col-12 col-sm-12 ntgr-contact-color">
                <nav className="d-grid gap-2 col-8">
                  <span className="rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                    <FontAwesomeIcon icon={faPhone} />
                    <div>
                      <strong className="d-block">Office</strong>
                      <small> +27 14 5350 066</small>
                    </div>
                  </span>
                  <span className="rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                    <FontAwesomeIcon icon={faMailBulk} />
                    <div>
                      <strong className="d-block">Email:</strong>
                      <small>info@ntgr.co.za</small>
                    </div>
                  </span>
                  <span className="rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start">
                    <FontAwesomeIcon icon={faBuilding} />
                    <div>
                      <strong className="d-block">Rustenburg Office</strong>
                      <small>
                        181A President Mbeki Drive Rustenburg 0300 North West
                        Province South Africa
                      </small>
                    </div>
                  </span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
