import React from "react";
import aboutBanner from "../Assets/about-banner.jpg";
const Projects = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Our Projects</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  We’re focused on delivering sustainable high-quality projects.
                  From concept to construction and operation, we do whatever it
                  takes to get your project done right.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12 mt-5">
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Project Description</th>

                        <th scope="col">Client Details & Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Supply and delivery of Electrical, plumbing and
                          Carpentry Material
                        </td>

                        <td>
                          Rustenburg Local Municipality <br />
                          Duration:
                          <br /> 1 December 2022 to 30 November 2025
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Supply of Nitrogen purging for refractionator plant
                        </td>

                        <td>
                          Transnet Pipelines
                          <br />
                          Duration:
                          <br /> 26 February 2024 to 25 May 2024
                        </td>
                      </tr>
                      <tr>
                        <td>Construction of New Concrete Retaining Wall</td>

                        <td>
                          Anglo American Smelters <br />
                          Duration:
                          <br /> January 2024 - September 2024
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Removal of broken timber poles , supply & installation
                          of new new treated timber poles
                        </td>

                        <td>
                          Northam Platinum Mine <br />
                          Duration:
                          <br /> March 2024
                        </td>
                      </tr>
                      <tr>
                        <td>Road Rehabilitation Phase 1</td>

                        <td>
                          Anglo American RBMR
                          <br />
                          Duration:
                          <br />
                          December 2022 – December 2024
                        </td>
                      </tr>
                      <tr>
                        <td>Road Rehabilitation Phase 1</td>

                        <td>
                          Anglo American RBMR
                          <br />
                          Duration:
                          <br />
                          Jan 2024 – April 2024
                        </td>
                      </tr>
                      <tr>
                        <td>Road Rehabilitation and resealing of streets</td>

                        <td>
                        Rustenburg Local Municipality 
                          <br />
                          Duration:
                          <br />
                          February 2021 to February 2024
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Project Name</th>
                        <th scope="col">Work Description</th>
                        <th scope="col">Client Details & Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Road construction and maintenance</td>
                        <td>1. Road Rehabilitation </td>
                        <td>
                          Anglo American <br /> Duration: <br />
                          December 2022 – March 2023
                        </td>
                      </tr>
                      <tr>
                        <td>Road construction and maintenance</td>
                        <td>1. Road Rehabilitation </td>
                        <td>
                          Anglo American <br /> Duration: <br />
                          December 2022 – March 2023
                        </td>
                      </tr>
                      <tr>
                        <td>Road construction and maintenance</td>
                        <td>
                          1. Road Rehabilitation <br />
                          2. Resealing of streets
                        </td>
                        <td>
                          Rustenburg Local Municipality Duration:
                          <br /> February 2021 to February 2024
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Design & Development of Mohlala Pedestrian Access
                          Bridge
                        </td>
                        <td>
                          1. Design & detail costing of bridge construction.
                          <br /> 2. Construction Monitoring
                        </td>
                        <td>
                          AES Consulting <br /> Duration: <br /> February 2018
                          to March 2020
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Conditional Assessment and Rehabilitation of SARB
                          Rotary UPS Infrastructure, (Durban & Cape Town
                          Branches)
                        </td>
                        <td>
                          1. Operational review of existing air-conditioners
                          <br />
                          2. Power usage analysis and recommendations for cost
                          benefit replacements
                        </td>
                        <td>Mani Industries</td>
                      </tr>
                      <tr>
                        <td>
                          Welkom Student Accommodation Design & Project
                          Management Development
                        </td>
                        <td>
                          1. Structural and Civil Design services
                          <br /> 2. Costing of scope of work.
                        </td>
                        <td>MMM Projects</td>
                      </tr>
                      <tr>
                        <td>
                          Design & Development of Marley Bronkhospruit Sand
                          Bunkers
                        </td>
                        <td>
                          1. Design & detail costing of bunker development{" "}
                          <br /> 2. Construction Monitoring.
                        </td>
                        <td>
                          Marley Roofing Systems
                          <br />
                          Duration:
                          <br /> October 2019 to March 2020
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Conditional Assessment of Sun International Hotels
                        </td>
                        <td>
                          1. Visual Inspection of buildings & civils
                          infrastructure <br />
                          2. Development of maintenance plans taking into
                          account alternative technologies
                        </td>
                        <td>
                          Mani Industries
                          <br />
                          Duration:
                          <br /> September 2018
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Design, Development and Costing of Alternative
                          Buildings for Eastern Cape & Gauteng Department of
                          Education for Habitat Modular Construction
                        </td>
                        <td>
                          1. Architectural & Structural Performance development
                          for Gauteng Schools infrastructure <br />
                          2. Costing of scope of work to be done.
                        </td>
                        <td>Habitat Modular Construction</td>
                      </tr>
                      <tr>
                        <td>
                          Assessment & review Marley Bronkhospruit production
                          plant (Structural, Electrical & Mechanical)
                        </td>
                        <td>
                          1. Analysis of critical components status &
                          recommendations <br />
                          2. Operations optimisation through components
                          replacement.
                        </td>
                        <td>
                          Marley Roofing Systems
                          <br /> Duration: <br /> October 2017 to February 2019
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Projects;
