import React from "react";
import assetManagement3 from "../Assets/assetManagement3.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const ConstructionServices = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Construction Services</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  We offer quality infrastructure design, road construction,
                  rehabilitation, upgrades and construction solutions from
                  planning, approval, pre-construction planning and
                  commissioning phases. Our experience spans over 15 years and
                  offer our clients the highest quality of workmanship and
                  experience to each project. We are at the forefront of using
                  technology driven methods for rehabilitation and upgrades
                  which reduces project costs and durations on projects that we
                  have worked on.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 mt-4">
            <img src={assetManagement3} className="width100" alt="NTGR" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Work Management Systems </h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  Our Maintenance work management approach seeks to integrate
                  maintenance technical services with support services to ensure
                  100% work order completion rate within predefined timeliness.
                  Our software applications and integrated management platform
                  ensure we deliver on our maintenance commitments through the
                  integration of maintenance support services and equipment
                  automated processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConstructionServices;
