import React from "react";
import assetManagement3 from "../Assets/assetManagement5.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const TurnkeySolutions = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Turn-key (EPCM) Engineering and Project Management</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  We have inhouse multidisciplinary engineering teams that
                  provides comprehensive EPC services for clients across diverse
                  industries, delivering capital efficiency and project
                  certainty. We are experienced in the EPCM/Turnkey project
                  contract suites such as FIDIC, NEC4 etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 mt-4">
            <img src={assetManagement3} className="width100" alt="NTGR" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TurnkeySolutions;
