import React from "react";
import aboutBanner from "../Assets/about-banner.jpg";
import project_01 from "../Assets/projects/01.jpg";
import project_02 from "../Assets/projects/02.jpg";
import project_03 from "../Assets/projects/03.jpg";
import project_04 from "../Assets/projects/04.jpg";
import project_05 from "../Assets/projects/05.jpg";
import project_06 from "../Assets/projects/06.jpg";
import project_08 from "../Assets/projects/08.jpg";
import project_09 from "../Assets/projects/09.jpg";
import project_10 from "../Assets/projects/10.jpg";
import project_11 from "../Assets/projects/11.jpg";
import project_12 from "../Assets/projects/12.jpg";
import project_13 from "../Assets/projects/13.jpg";
const Gallery = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Our Gallery</h3>

            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <div className="row justify-content-center">
                  <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_11} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_10} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_09} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_08} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_01} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_02} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_03} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_04} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_05} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_06} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_12} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={project_13} className="width100" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Gallery;
