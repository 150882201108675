import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
const Header = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-header">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12">
            <div className="row justify-content-end">
              <div className="col-lg-8 col-md-8 col-6 col-sm-6">
                <ul className="list-unstyled d-flex ntgr-remove-margin">
                  <li className="ms-3">
                    <a
                      target="_blanl"
                      className="link-dark ntgr-header-icons"
                      href="https://www.linkedin.com/company/ntgr-engineering-projects-cc/"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      target="_blanl"
                      className="link-dark ntgr-header-icons"
                      href="https://www.youtube.com/@NTGREngineering"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-4 col-lg-4 col-md-4 col-6 col-sm-6">
                <p className="ntgr-text-end">
                   +27 14 5350 066 | info@ntgr.co.za
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
