import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import aboutBanner from "../Assets/about-banner.jpg";
const NewsPage = () => {
  const [post, setPost] = useState("");
  const { id } = useParams();
  const getNewsItem = async () => {
    try {
      await axios
        .get(`https://ntgr.co.za/backend/wp-json/wp/v2/posts/${id}`)
        .then((response) => {
          setPost(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNewsItem();
  });
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-otherpages ntgr-news-section">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>
              {post && (
                <div
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                />
              )}
            </h3>
            <small style={{ color: "red", fontSize: "12px" }}>
              Date posted
              {post && <div dangerouslySetInnerHTML={{ __html: post.date }} />}
            </small>
            <br />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                {post && (
                  <div
                    dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsPage;
