import React from "react";
import assetManagement4 from "../Assets/assetManagement4.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const EngineeringDesign = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Engineering Designs</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  Our core engineering services provide complete engineering
                  designs and engineering support services in the Civil,
                  Mechanical, Electrical sector, implementing innovative
                  solutions to add real value using state of the art design
                  technics coupled with our advanced use of BIM platforms.{" "}
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-12 col-sm-12 mt-4">
                <img src={assetManagement4} className="width100" alt="NTGR" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EngineeringDesign;
