import React from "react";
import assetManagement from "../Assets/assetManagement.jpg";
import assetManagement2 from "../Assets/assetManagement2.jpg";
import aboutBanner from "../Assets/about-banner.jpg";
const AssetManagement = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Asset Management</h3>
            <p>
              Our unique value proposition covering Civil, Structural,
              Mechanical, Electrical & Instrumentation, is the{" "}
              <strong>INTEGRATION</strong> of full asset lifecycle activities
              for; asset creation, operations, maintenance and utilization
              management as well as disposal activities. This is enabled by
              consistent decision-making methods, risk management and{" "}
              <strong>TECHNOLOGY IMPLEMENTATION.</strong>
            </p>
            <div className="row">
              <div className="col-lg-5 col-md-5 col-12 col-sm-12">
                <p className="text-center">
                  <img src={assetManagement} alt="NTGR" className="width100" />
                </p>
              </div>
              <div className="col-lg-7 col-md-6 col-12 col-sm-12">
                <h4 className="mt-5">Physical Asset Management</h4>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        The Maintenance Control Function is enabled by:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            {" "}
                            Skilled engineers and technicians.
                          </li>
                          <li className="list-group-item">
                            Integrated contractor management.
                          </li>
                          <li className="list-group-item">
                            Optimized work management.
                          </li>
                          <li className="list-group-item">
                            Life cycle studies and history management.
                          </li>
                          <li className="list-group-item">
                            Rehabilitation work based on gap analysis.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        The Reliability Based Maintenance management function is
                        defined by:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            Equipment best practices.
                          </li>
                          <li className="list-group-item">
                            Standard operating procedures.
                          </li>
                          <li className="list-group-item">
                            Intelligent maintenance practices.
                          </li>
                          <li className="list-group-item">
                            Structural safety requirements.
                          </li>
                          <li className="list-group-item">
                            Health monitoring of infrastructure.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        The Software Applications:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            Software applications that automate assets. and
                            integrate work management processes.
                          </li>
                          <li className="list-group-item">
                            Data analytics and artificial intelligence.
                          </li>
                          <li className="list-group-item">
                            Infrastructure management through GIS.
                          </li>
                          <li className="list-group-item">
                            Process built Cloud services.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        The Overall Goal being to:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            Eliminate Failures and delays.
                          </li>
                          <li className="list-group-item">
                            Improve asset performance.
                          </li>
                          <li className="list-group-item">
                            Reduce total costs of facilities management.
                          </li>
                          <li className="list-group-item">
                            Create a safe working environment.
                          </li>
                          <li className="list-group-item">
                            Increase infrastructure durability.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-12 col-sm-12">
                <h4 className="mt-5 text-center">
                  Diagramatic Aproach Physical Asset Management
                </h4>
                <img src={assetManagement2} className="width100" alt="NTGR" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssetManagement;
