import React from "react";
import aboutBanner from "../Assets/about-banner.jpg";
import accreditation from "../Assets/accreditation.jpg";
import client_01 from "../Assets/partners/01.jpg";
import client_02 from "../Assets/partners/02.jpg";

const PartnersAndAccreditations = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Partners and Accreditations</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <p>
                  We are proud to be accredited and affiliated with leading
                  institutions
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <h6 className="text-center">Our partners</h6>
                <div className="row justify-content-center">
                  <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 justify-content-center">
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_01} className="width100" alt="..." />
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="p-3 border bg-light text-center">
                        <img src={client_02} className="width100" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <h6 className="text-center">Accreditations</h6>
                <p className="text-center">
                  <img src={accreditation} width="30%" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersAndAccreditations;
