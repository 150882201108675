import React, { useState, useEffect } from "react";
import axios from "axios";
import aboutBanner from "../Assets/about-banner.jpg";
import NewsPostItem from "./NewsItems";
const News = () => {
  const [posts, setPosts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getNews = async () => {
    try {
      const response = await axios
        .get("https://ntgr.co.za/backend/wp-json/wp/v2/posts?categories=1", {})
        .then((response) => {
          setPosts(response.data);
          setIsLoaded(true);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12 ntgr-otherpages">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 p-0 ntgr-pages-banner">
            <img src={aboutBanner} className="width100" alt="About us banner" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-12 col-sm-12 mt-5">
            <h3>Publications and News</h3>
            {isLoaded ? (
              <div className="row">
                {posts.map((post) => (
                  <NewsPostItem post={post} key={post.id} />
                ))}
              </div>
            ) : (
              <div className="spinner-grow text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default News;
